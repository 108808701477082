<template>
  <base-section id="pro-features">
  <base-section-heading title="Pricing"> </base-section-heading>
    <v-container>
      <v-row justify="center">
      <div
        style="color: black; padding: 16px; font-weight: bold; text-align: center;"
      >
        We believe in caring. To prove it, Tentifly offers only one single package with all available features. Moreover, you can get started with full functionality, no restrictions and without Tentifly support for only a FIVER! 
      </div>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" lg="4" md="5" sm="6">
          <v-card class="mx-auto" style="max-width: 400px;">
            <v-card-title style="display: block;">
              <v-row>
                <v-spacer /><span class="display-1">INDIVIDUAL</span><v-spacer />
              </v-row>
              <v-row> <v-spacer />subscription<v-spacer /> </v-row>
            </v-card-title>
            <v-card-text style="text-align: center;">
              <span style="font-size: 24px">5€</span> / month
              <p>Taxes apply</p>
            </v-card-text>
            <v-card-text>
              <v-icon color="primary"> mdi-check-bold </v-icon> Unlimited orders &
              bookings
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> Unlimited forms &
              external applications
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> Online shop page (Add on you webpage, Soon!)
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> Form add-on plugin on your existing webpage
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> Tentifly marketplace spot (Soon!)
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> Unlimited employees &
              users
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> Employee motivation
              system & schedules
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> CRM & customer
              history
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> User & Customer apps (SOon!)
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> Tentifly assistant
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> PIM & stocktaking
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> Tentifly marketing
              tools & addons (Soon!)
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> All free & premium
              add-ons
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> 24/7 availability
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> Updates & new
              features
            </v-card-text>
            <v-card-actions style="padding: 16px;">
              <v-spacer />
              <router-link :to="{ name: 'Contact' }">
                <base-btn>
                  Get started
                </base-btn>
              </router-link>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4" md="5" sm="6">
          <v-card class="mx-auto" style="max-width: 400px;">
            <div style="padding: 8px; padding-left: 16px; padding-right: 16px; background: linear-gradient(90deg, rgb(92, 0, 37) 0%, rgb(255, 56, 92) 100%); border-radius: 4px; position: absolute; right: 0px; top: 0px; color: white; font-weight: bold; font-size: large; display: none;">POPULAR</div>
            <v-card-title style="display: block;">
              <v-row>
                <v-spacer /><span class="display-1">PRO</span><v-spacer />
              </v-row>
              <v-row> <v-spacer />subscription<v-spacer /> </v-row>
            </v-card-title>
            <v-card-text style="text-align: center;">
              <span style="font-size: 24px">15€</span> / month
              <p>Taxes apply</p>
            </v-card-text>
            <v-card-text>
              <v-icon color="primary"> mdi-check-bold </v-icon> Unlimited orders &
              bookings
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> Unlimited forms &
              external applications
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> Online shop page (Add on you webpage, Soon!)
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> Form add-on plugin on your existing webpage
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> Tentifly marketplace spot (Soon!)
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> Unlimited employees &
              users
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> Employee motivation
              system & schedules
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> CRM & customer
              history
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> User & Customer apps (SOon!)
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> Tentifly assistant
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> PIM & stocktaking
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> Tentifly marketing
              tools & addons (Soon!)
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> All free & premium
              add-ons
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> 24/7 availability
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> Updates & new
              features
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> Support via E-mail
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> Training (Hourly fee applies, free with 6 months)
              <v-divider style="margin-top: 8px; margin-bottom: 8px;" />
              <v-icon color="primary"> mdi-check-bold </v-icon> Help with setup (Hourly fee applies)
            </v-card-text>
            <v-card-actions style="padding: 16px;">
              <v-spacer />
              <router-link :to="{ name: 'Contact' }">
                <base-btn>
                  Get started
                </base-btn>
              </router-link>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="3" md="3" style="display: none;">
          <v-simple-table>
            <thead>
              <tr>
                <th />
                <th class="body-1 font-weight-bold text-center">
                  What's in the FREE version?
                </th>
              </tr>
            </thead>

            <tbody class="body-1">
              <tr v-for="([name, one, two], i) in features" :key="i">
                <td v-text="name" />

                <td class="text-center font-weight-bold">
                  <template v-if="typeof one === 'boolean'">
                    <v-icon :color="one ? 'success' : 'error'">
                      mdi-{{ one ? "check" : "close" }}
                    </v-icon>
                  </template>

                  <template v-else>
                    {{ one }}
                  </template>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionProChart",

  data: () => ({
    features: [
      ["Components", 23, "40+"],
      ["Example Pages", 3, 10],
      ["Bugfixes and Issues", true, true],
      ["Vue CLI Support", true, true],
      ["News, Project, and Pricing Pages", false, true],
      ["6 Months Free Updates", false, true],
      ["Price", "Free", "$60"]
    ]
  })
};
</script>
